import WebsiteModelContainer from "./website/WebsiteModelContainer";
import IllustrationModelContainer from "./illustration/IllustrationModelContainer";
import Counter from "./Counter";
import ComputerModelContainer from "./computer/ComputerModelContainer";
import "./Services.css";
import { motion, useInView } from "motion/react";
import { useRef, useState } from "react";
import marketing from "./marketing.png"
import development from "./development.png"
import programing from "./programing.png"


const textVariants = {
  initial: {
    x: -100,
    y: -100,
    opacity: 0,
  },
  animate: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};

const listVariants = {
  initial: {
    x: -100,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.5,
    },
  },
};

const services = [
  {
    id: 1,
    img: development,
    title: "פיתוח אתרים ואפליקציות",
    // counter: 35,
  },
  {
    id: 2,
    img: marketing,
    title: "שיווק",
    // counter: 23,
  },
  {
    id: 3,
    img:programing,
    title: "חוג תכנות",
    // counter: 46,
  },
];

const Services = () => {
  const [currentServiceId, setCurrentServiceId] = useState(1);
  const ref = useRef();
  const isInView = useInView(ref, { margin: "-200px" });
  return (
<div className="services" ref={ref} dir="rtl">
  <div className="sSection left">
    <motion.h1
      variants={textVariants}
      animate={isInView ? "animate" : "initial"}
      className="sTitle"
    >
      איך אפשר לעזור?
    </motion.h1>
    <motion.div
      variants={listVariants}
      animate={isInView ? "animate" : "initial"}
      className="serviceList"
    >
      {services.map((service) => (
      <motion.div
      variants={listVariants}
      className="service"
      key={service.id}
      onClick={() => setCurrentServiceId(service.id)}
    >
          <div className="serviceIcon">
            <img src={service.img} alt={service.title} />
          </div>
          <div className="serviceInfo">
            <h2>{service.title}</h2>
            <h3>{service.counter} Projects</h3>
          </div>
        </motion.div>
      ))}
    </motion.div>
    {/* <div className="counterList">
      <Counter from={0} to={104} text="Projects Completed" />
      <Counter from={0} to={72} text="Happy Clients" />
    </div> */}
  </div>
  <div className="sSection right">
    {currentServiceId === 1 ? (
      <>
        <WebsiteModelContainer />
       
        <p> <span class="gradient-ptext">פיתוח אתרים:</span>   עם קוד מותאם אישית מאפשר יצירת אתרים ברמה גבוהה שמותאמים במדויק לצרכי הלקוח ולחזון העסקי שלו.</p>
        <p>
אתרים אלו נבנים בטכנולוגיות מתקדמות המספקות גמישות בלתי מוגבלת, יכולת הרחבה, אבטחה מוגברת וביצועים מעולים.
<p>
היתרונות של אתרים מבוססי קוד על פני אתרים שנבנו באמצעות בוני אתרים כוללים התאמה אישית מלאה של עיצוב ופונקציונליות.</p>
גישה לאופטימיזציה מתקדמת למנועי חיפוש (SEO), ותמיכה בתכונות ייחודיות שאינן זמינות בבוני אתרים.</p><p>
הבחירה בפיתוח אתרים בקוד מבטיחה לעסק שלך פתרון טכנולוגי שמאפשר לו לבלוט ולהשיג יתרון תחרותי בעולם הדיגיטלי.,</p>
      </>
    ) : currentServiceId === 2 ? (
      <>
        <IllustrationModelContainer />
        <p> <span class="gradient-ptext">שיווק:</span>  שיווק הוא הצעד המשלים והקריטי לאחר בניית האתר, שמבטיח שהעסק שלך יגיע לקהל היעד הנכון ויממש את מטרותיו. </p>
        <p>לאחר פיתוח אתר מותאם אישית, אני מציע שירותי שיווק דיגיטלי מתקדמים</p>
        <p>   כולל אופטימיזציה למנועי חיפוש (SEO), ניהול קמפיינים ממומנים בגוגל וברשתות חברתיות, </p>
        <p>  ושיפור חוויית משתמש (UX) כדי להגדיל את שיעור ההמרה באתר. </p>
        <p> בנוסף, אני מתמקד ביצירת תוכן איכותי ומדויק שמחזק את הנוכחות הדיגיטלית של העסק שלך ומשפר את אמינותו בעיני הלקוחות. </p>
        <p>תהליך השיווק נעשה בהתאמה אישית למטרות העסק, עם דגש על מעקב וניתוח נתונים כדי לוודא שההשקעה שלך מניבה תוצאות מרביות.</p>
      </>
    ) : (
      <>
        <ComputerModelContainer />
        <p>חוג התכנות שאני מציע כולל מגוון קורסים ייחודיים, שכל אחד מתמקד בתחום מרכזי בעולם הפיתוח והמדעים.</p>
        <p><span class="gradient-ptext">פיתוח אתרים (Frontend ו-Backend)</span>: לימוד בניית אתרים מקצועיים עם דגש על טכנולוגיות צד לקוח כמו HTML ו-JavaScript,
        <p> וצד שרת עם Node.js ו-Express.</p></p>
        <p><span class="gradient-ptext">פיתוח אתרי Fullstack:</span> שילוב בין צד לקוח וצד שרת באמצעות React, MongoDB, וכלים לניהול פרויקטים כמו Git.</p>
        <p><span class="gradient-ptext">פיתוח משחקים ומדעי המחשב:</span> יצירת משחקים עם Unity או Godot, תוך הבנת עקרונות אלגוריתמיים ומנועי פיזיקה.</p>
        <p><span class="gradient-ptext">תכנות בסיסי:</span> קורס מבוא שמלמד שפות כמו Assembly ו-BASIC להבנת היסודות.</p>
        <p><span class="gradient-ptext">מדעי המחשב:</span> קורס מעמיק בנושאים כמו אלגוריתמים, מבני נתונים, וארכיטקטורת מחשבים.</p>
      </>
    )}
  </div>
</div>

  );
};

export default Services;
