import Navbar from "./Sections/Navbar";
import Services from "./Sections/services/Services";
import About from "./Sections/About";
import Contact from "./Sections/Contact/Contact";
import Background from "./components/Background/Background"; 
import Hero from "./Sections/Hero/HeroMain";
import Goals from './Sections/Goals/page';
import PortfolioMain from './components/projectsMain/PortfolioMain'
import Footer  from "./Sections/Footer/Footer2";
import ProgrammingClass from "./pages/ProgrammingClass/ProgrammingClass";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import WhatsAppButton from "./components/WhatsAppButton/WhatsAppButton"
import ThemeToggle from "./components/ThemeToggle";
import ScrollToTop from "./components/ScrollToTop";
import "swiper/css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Test3d from "./components/Test3d";


function App() {
  return (
    <Router>
      <ScrollToTop /> {/* רכיב זה יגלול לראש העמוד */}
      <Navbar />

      <div id="main-content" style={{ position: "relative", zIndex: 0, height: "100%" }}>
      <Routes>
        <Route
          path="/"
          element={
            <div className="homepage">
              <section id="#home">
                <Hero />
              </section>
              <section id="#Services">
                <Services />
              </section>
              <section id="goals">
                <Goals />
              </section>
              {/* <section id="#Portfolio">
                <Projects />
              </section> */}
              <section id="#Test3d">
     <PortfolioMain/>
              </section>
              <section id="#Contact">
                <Contact />
              </section>

              <FloatingWhatsApp />
              <Footer/>
            </div>
          }
        />
        <Route path="services" element={<Services />} />
        <Route path="about" element={<About />} />
        <Route path="/programmingClass" element={<ProgrammingClass />} />
      </Routes>
      </div>
      <WhatsAppButton />
      <Footer />
    </Router>
  );
}

export default App;

