import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./InfoSection.css"; // ייבוא ה-CSS

gsap.registerPlugin(ScrollTrigger);

const InfoSection = () => {
  useEffect(() => {
    gsap.fromTo(
      ".wrap",
      { background: "#000" }, // צבע התחלה
      {
        backgroundColor: "rgba(23, 122, 252, 1)", // צבע סופי
        scrollTrigger: {
          trigger: ".wrap",
          start: "top 30%", // מתחיל כשהאלמנט נכנס למסך
          end: "bottom 20%", // מסתיים קצת לפני שהאלמנט יוצא מהמסך
          scrub: true, // שינוי הדרגתי
        },
      }
    );

    // ניקוי ScrollTrigger בזמן הרכיב מתפרק
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <section
      className={`relative w-full h-screen mx-auto bg-pattern`}
   
    >
      <div className="wrap min-h-screen flex items-center justify-center">
        <div className="info">
  
        </div>
        <div className="color-light"></div>
        <h1
            className="text-5xl md:text-6xl lg:text-8xl font-bold text-shadow-md text-center"
            style={{ color: "white", zIndex: 2, position: "relative" }} // שמירה על צבע טקסט קבוע
          >
            <span>Become a </span>
            <span style={{ color: "#FFD700" }}>Developer</span> {/* צבע צהוב קבוע */}
          </h1>
      </div>
    </section>
  );
};

export default InfoSection;
