import React from 'react';
import { Typewriter } from 'react-simple-typewriter';
import './TextEffect.css';

const TextEffect = () => {
  return (
    <div className="text-effect">
      <Typewriter
        words={["I Love Coding.", "and to Learn.", "and to Explore."]}
        loop={true}
        typeSpeed={50}
        deleteSpeed={30}
        delaySpeed={1000}
      />
      <span className="highlight"></span>
    </div>
  );
};

export default TextEffect;
