import React from 'react';
import "./footer.css";
import FooterImg from "../../assets/footer_img.png"

export default function Content() {
  return (
    <div className='bg-[#4E4E5A] py-8 px-12 h-full w-full flex flex-col justify-center items-center'>
      <Section />
    </div>
  );
}

const Section = () => {
  return (
    <div className='text-center' dir='rtl'>
      <h1 className='text-[10vw] leading-[0.8] mb-7'>More-Ways</h1>
      <p className='text-[3vw] leading-[1] mt-32'>© 2025 More-Ways. All Rights Reserved.</p>
      {/* תמונה */}
      <div className="flex justify-center">
        <img
          src={FooterImg}
          alt="Footer Illustration"
         className="w-[90%] sm:w-[70%] md:w-[60%] lg:w-[50%] h-auto !important"
        />
      </div>
    </div>
  );
};
