import React, { useRef } from "react";


import Achievement from "../../components/Achievement";
import "./About.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const container = useRef();
  useGSAP(
    () => {
      gsap
        .timeline({
          delay: 0.5,
          scrollTrigger: {
            trigger: container.current,
            start: "20%% bottom",
            end: "bottom top",
          },
        })
        .fromTo(".about__image", { x: -50, opacity: 0 }, { x: 0, opacity: 1 })
        .fromTo(
          [
            ".title",
            ".sub__heading",
            ".section__header .description",
            ".keypoint",
          ],
          { opacity: 0 },
          { opacity: 1, stagger: 0.5 }
        );
    },
    { scope: container }
  );

  return (
    <section id="about" ref={container} dir="rtl">
           <div className="overlay">
      <div className="container">
        <div className="grid">
          <div className="about__image">
   
          </div>
          <div className="section__header">
            <h3 className="title primary">אודותינו</h3>
            <h2 className="sub__title">בר קרגו - מקצועיות ויצירתיות</h2>
            <p className="description">
              חברת בר קרגו נוסדה בשנת 2020, ע"י ריקי בר מנכ"לית החברה.
              ריקי, בעלת ניסיון של 40 שנה בתחום שרשרת האספקה, המנווטת את הצוות ביד רמה למצוינות מול הלקוחות.
            </p>
            <p className="description">
              במהלך השנים ריקי הובילה פרויקטים גדולים ומורכבים ואף שירתה לקוחות מהשורה הראשונה בייבוא ובייצוא מול ישראל.
            </p>
            <p className="description">
              בחברת בר קרגו הלקוח הוא מעל הכל, ולכן אנו דואגים שהמשלוחים יתנהלו במקצועיות ויצירתיות, תוך הבנת צורכי הלקוח ותפירת פתרונות בזמן אמת.
            </p>
            <p className="description">
              אנו עובדים מול סוכנים בכל העולם, כולל סוכני נישה, שמטפלים במשלוחים חריגים / פרויקטים על מנת לתת ללקוח מגוון אפשרויות.
            </p>
            <p className="description">
              בין לקוחותינו אפשר למצוא את עסקי הברזל, השקיה, פלסטיקה, חקלאות ועוד.
              אנו מתנהלים באופן טכנולוגי ואישי מול ספקי השירות: חב' ספנות, חב' תעופה, מובילים יבשתיים ועוד.
            </p>
            <p className="description">
              הצוות מציע זמינות אמיתית של 24/7, תוך מתן מענה מידי ללקוח.
              אנו מציעים חוויית שירות בוטיק עם מחירים הוגנים בשוק.
            </p>
          </div>
        </div>
        <div className="achieve">
          <div className="overlay grid">
            <div className="section__header">
              <h3 className="title primary">הישגינו</h3>
              <h2 className="sub__heading">הופכים חזון למציאות</h2>
            </div>
            <div>
              <Achievement />
            </div>
          </div>
        </div>
      </div>
      </div>
    </section>
  );
};

export default About;
