import React from "react";
import Paragraph from "./Paragraph"; // נתיב מותאם לרכיב ה-Paragraph

const Hero = () => {
  const text =
    "אני מלמד בגישה של איך ללמוד מהרשת עם זה מספריות תוכנה ואם זה בעזרת קסם הבינה המלאכותית.";

  return (
    <section dir="rtl" className="hero-section relative w-full h-screen -bg-primary  --text-color">
      <div className="absolute inset-0 top-[120px] max-w-7xl mx-auto flex flex-col items-start gap-5">
        {/* הוספת אפקט הפסקה */}
        <Paragraph paragraph={text}  />
      </div>
    </section>
  );
};

export default Hero;
