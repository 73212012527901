import WaterEffect from "./WaterEffect/WaterEffect"
import InfoSection from "./InfoSection/InfoSection"
import Hero from "./Hero/Hero";
import Skills from  "../../components/Skills/index"
import ZoomParallax from "./ZoomParallax/index"
import Experience from "./Experience";
import Tech from "./Tech";
import WorksProgreming from './projectsProgramin/WorksProgreming'
import "./programming-page.css";
import ImageComponent from "./ImageComponent/ImageComponent"






const ProgrammingClass = () => {
  return (
    <div className="programming-page relative z-0"
  
  >
      {/* הצב את Navbar מעל הכל */}
      <WaterEffect />
      <InfoSection/>
      <ZoomParallax/>
      

      {/* רכיב Hero */}
      <div className="   bg-cover bg-no-repeat bg-center">
      <Hero />
      </div>


      {/* רכיבים עם רקע */}
      <div className=" ">
        <Experience />
      </div>
      <div className="">
        <Tech />
      </div>
      <div className="">
        <WorksProgreming/>
        <ImageComponent/>
        
      </div>
 
    </div>
  );
};

export default ProgrammingClass;
