import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import avatar from "../../assets/logo.png"; // Path to your custom image

const WhatsAppButton = () => {
  return (
    <div>
      <FloatingWhatsApp
        phoneNumber="+9727350087" // Your WhatsApp number
        accountName="Bar Cargo" // Name displayed on the WhatsApp widget
        statusMessage="זמין לשאלות" // Optional status message
        avatar={avatar} // Path to your avatar image
        chatMessage="שלום! איך אפשר לעזור לך היום?" // Predefined message in the chat
        placeholder="כתוב הודעה כאן..." // Placeholder text for the input
        darkMode={false} // Optional: set dark mode
      />
    </div>
  );
};

export default WhatsAppButton;
