import { TypeAnimation } from "react-type-animation";
import { motion } from "motion/react";
import man from "./man.png";

const Speech = () => {
  return (
    <motion.div
      className="bubbleContainer"
      animate={{ opacity: [0, 1] }}
      transition={{ duration: 1 }}
    >
      {/* בועה עם תמונת פרופיל במרכז */}
      <div className="bubbleWrapper">
        <div className="bubble">
          <img src={man} alt="User Profile" className="profileImage" />
          <TypeAnimation
            sequence={[
              1000,
              "Lorem ipsum dolor sit amet lerinat consectetur adipisicing.",
              1000,
            ]}
            wrapper="span"
            speed={40}
            deletionSpeed={60}
            repeat={Infinity}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Speech;
