import {
    FaTools,
    FaCheckCircle,
    FaClock,
    FaDraftingCompass,
    FaUserFriends,
    FaFacebook,
    FaInstagram,
    FaLinkedin,
    FaYoutube,
    FaHome,
    FaBuilding,
  } from "react-icons/fa";


  export const navigations = [
    {
      label: "בית",
      to: "/",
    },

    {
      label: "אודות",
      to: "/about", // Updated for routing
    },
    {
      label: "חוג תכנות",
      to: "/ProgrammingClass", // Updated for routing
    },
  ];

  export const keypoints = [
    {
      title: "Expert Craftsmanship",
      description:"Every detail matters. We ensure high-quality workmanship in every phase of construction."
    },
    {
      title: "On-Time, Within Budget",
      description:"Efficiency is key. We deliver your projects on schedule, without unexpected costs."
    },
    {
      title: "Innovative Solutions",
      description:"We use the latest technologies and sustainable materials to build for the future."
    },
    {
      title: "Client-Centered Approach",
      description:"We listen to your needs and tailor our services to exceed your expectations."
    },
  ];
  

  

  
  export const socialHandles = [
    {
      name: "Facebook",
      icon: <FaFacebook />,
      link: "https://www.facebook.com",
    },
    {
      name: "Instagram",
      icon: <FaInstagram />,
      link: "https://www.instagram.com",
    },
    {
      name: "LinkedIn",
      icon: <FaLinkedin />,
      link: "https://www.linkedin.com",
    },
    {
      name: "Youtube",
      icon: <FaYoutube />,
      link: "https://www.youtube.com/channel/UCEtnsPZQEd0l1tbr_nDQd5Q?sub_confirmation=1",
    },
  ];