
import webDevelopmentImage from './webdevelopment.png';
import marketing from "./marketing.png"
import programingclass from "./programingclass.png";



export const projects = [
  {
    title: 'פיתוח אתרים ואפליקציות',
    description: `, דגש רב על אתרים מותאמים ללקוח עם קוד והיתרונות בכך
  `,
      src: webDevelopmentImage,
    color: '#0384fc',
  },
  {
    title: 'marketing',
    description:
      'כמה שהאתר של העסק שלך נוצץ, ללוא תנעה בו זה לא יעבוד.',
    src: marketing,
    color: '#fc036f',
  },
  {
    title: 'programingclass',
    description:
      'קסם התכנות בשלל דרכים כגון: פיתוח אתרים, משחקים, ו-Data Science ',
    src: programingclass,
       color: '#C2491D',
  },
  
];
