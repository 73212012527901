import React, { useRef, useEffect } from 'react';
import { motion, useScroll } from 'framer-motion';
import Lenis from '@studio-freight/lenis';
import Card from './index'; // ייבוא הרכיב
import styles from './style.module.scss';
import { projects } from './data'; // ייבוא הנתונים

const Goals = () => {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start start', 'end end'],
  });

  useEffect(() => {
    const lenis = new Lenis();

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return () => lenis.destroy();
  }, []);

  return (
    <main ref={container} className={styles.main}>
      {projects.map((project, i) => {
        const targetScale = 1 - (projects.length - i) * 0.05;
        return (
          <Card
            key={`p_${i}`}
            i={i}
            {...project}
            progress={scrollYProgress}
            src={project.src} // שימוש בנתיב שנוצר מייבוא
            range={[i * 0.25, 1]}
            targetScale={targetScale}
          />
        );
      })}
    </main>
  );
};

export default Goals;

