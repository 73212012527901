import React from 'react'
import "./Logo.css"
import logo from "../../assets/logo.png"

const Logo = ({ size = 60 }) => {
  return (
    <div className='flex__center logo__container'>
        <div 
          className="flex__center icon__container" 
          style={{ width: `${size}px`, height: `${size}px` }}
        >
            <img src={logo} alt="Logo" />
        </div>
    </div>
  )
}

export default Logo;
