import React from 'react'
import { useGLTF } from '@react-three/drei'

export function IllustrationModel(props) {
  const { nodes, materials } = useGLTF('/Illustration.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube.geometry} material={materials.Material} />
      <mesh geometry={nodes.UI001.geometry} material={materials['1']} position={[0, 2.276, -0.334]} />
      <mesh geometry={nodes.UI002.geometry} material={materials['1']} position={[-2.272, 3.868, -0.315]} />
      <mesh geometry={nodes.UI003.geometry} material={materials['1']} position={[-3, 2.876, 0.279]} />
      <mesh geometry={nodes.UI004.geometry} material={materials['1']} position={[-0.408, 0.722, 0.427]} />
      <mesh geometry={nodes.UI005.geometry} material={materials['1']} position={[0.744, 0.622, 0.111]} />
      <mesh geometry={nodes.UI006.geometry} material={materials['1']} position={[-1.264, 2.447, -0.26]} />
      <mesh geometry={nodes.UI008.geometry} material={materials['1']} position={[1.261, 2.447, -0.283]} />
      <mesh geometry={nodes.UI007.geometry} material={materials['1']} position={[2.529, 2.493, 0.319]} />
      <mesh geometry={nodes.UI009.geometry} material={materials['1']} position={[-0.9, 3.463, -0.29]} />
      <mesh geometry={nodes.UI010.geometry} material={materials['1']} position={[0.8, 0.15, 1.479]} />
      <mesh geometry={nodes.UI011.geometry} material={materials['1']} position={[-3.568, 0.45, 0.454]} />
      <mesh geometry={nodes.UI012.geometry} material={materials['1']} position={[-4.224, 1.693, -0.002]} />
      <mesh geometry={nodes.UI013.geometry} material={materials['1']} position={[-1.264, 1.047, -0.293]} />
      <mesh geometry={nodes.UI014.geometry} material={materials['1']} position={[1.236, 1.047, -0.293]} />
      <mesh geometry={nodes.UI015.geometry} material={materials['1']} position={[-1.932, 0.571, 0.256]} />
      <mesh geometry={nodes.UI016.geometry} material={materials['1']} position={[-4.24, 0.082, 1.55]} />
      <mesh geometry={nodes.UI017.geometry} material={materials['1']} position={[-3.04, 0.082, 2.75]} />
      <mesh geometry={nodes.UI018.geometry} material={materials['1']} position={[-1.94, 0.082, 1.35]} />
      <mesh geometry={nodes.UI019.geometry} material={materials['1']} position={[3.56, 0.082, 1.35]} />
      <mesh geometry={nodes.UI020.geometry} material={materials['1']} position={[2.66, 0.082, 2.65]} />
      <mesh geometry={nodes.UI021.geometry} material={materials['1']} position={[3.377, 3.074, 0.137]} />
      <mesh geometry={nodes.UI.geometry} material={materials['1']} position={[3.508, 0.992, 0.129]} />
    </group>
  )
}

useGLTF.preload('/Illustration.glb')
