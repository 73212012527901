import React from "react";
import "./WaterEffect.css";
import BackgroundImage from "../slider.jpg";
import TextEffect from "../TextEffect/TextEffect"; // ייבוא רכיב הטקסט

const WaterEffect = () => {
  return (
    <section
      className="water-effect-container"
      style={{
        background: `url(${BackgroundImage}) no-repeat center center fixed`,
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <div
        className="relative w-full h-screen mx-auto"
        style={{ backgroundColor: "var(--bg-primary)" }}
      >
        <div className="water-effect-content">
          <TextEffect /> {/* שילוב רכיב הטקסט */}

          {/* שכבת הכהות */}
          <div className="slider-overlay"></div>
        </div>
      </div>
    </section>
  );
};

export default WaterEffect;
