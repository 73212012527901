import React, { useState, useEffect } from "react";

import { Canvas } from "@react-three/fiber";
import "./hero.css";
import Speech from "./Speech";
import { motion } from "motion/react";
// import Shape from "./Shape";
import { Suspense } from "react";
import award1 from "./award1.png";
import award2 from "./award2.png";
import award3 from "./award3.png";
import facebook from "./facebook.png"
import  instagram from "./instagram.png";
import youtube from "./youtube.png";
import hero from "./hero.png"


const awardVariants = {
  initial: {
    x: -100,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.2,
    },
  },
};

const followVariants = {
  initial: {
    y: -100,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.2,
    },
  },
};

const Hero = () => {
  const [currentWord, setCurrentWord] = useState(0);
  const words = ["בניית אתרים", "שיווק", "חוגי תכנות"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((prev) => (prev + 1) % words.length); // מחליף בין המילים במחזוריות
    }, 3000); // שינוי כל 2 שניות
    return () => clearInterval(interval); // מנקה את ה-interval בזמן שהרכיב מתעדכן
  }, []);


  return (
    <div className="hero" dir="rtl">
      <div className="hSection left">
        {/* TITLE */}
        <motion.h1
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className="hTitle"
        >
    <h1 class="gradient-text">More-Ways</h1>
        <br/>
        
          <span className="small-text">{words[currentWord]}</span>
        </motion.h1>

    
      </div>
      <div className="hSection right">
        {/* FOLLOW */}
        <motion.div
          variants={followVariants}
          initial="initial"
          animate="animate"
          className="follow"
        >
          <motion.a variants={followVariants} href="/">
            <img src={instagram} alt="" />
          </motion.a>
          <motion.a variants={followVariants} href="/">
            <img src={facebook} alt="" />
          </motion.a>
          <motion.a variants={followVariants} href="/">
            <img src={youtube} alt="" />
          </motion.a>
          <motion.div variants={followVariants} className="followTextContainer">
            <div className="followText">FOLLOW ME</div>
          </motion.div>
        </motion.div>
        {/* BUBBLE */}
        <Speech />
  
        {/* CERTIFICATE */}
        {/* <motion.div
          animate={{ opacity: [0, 1] }}
          transition={{ duration: 1 }}
          className="certificate"
        >
          <img src={certificate} alt="" />
          LMA CERTIFICED
          <br />
          PROFESSIONAL
          <br />
          UI DESIGNER
        </motion.div> */}
        {/* CONTACT BUTTON */}
   
        
      </div>
      <div className="bg">
        {/* 3d */}
        <Canvas>
          <Suspense fallback="loading...">
            {/* <Shape />   */}
          </Suspense>
        </Canvas>
        
        <div className="hImg">
          <img src={hero} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Hero;



{/* <svg viewBox="0 0 200 200" width="150" height="150">
<circle cx="100" cy="100" r="90" fill="pink" />
<path
  id="innerCirclePath"
  fill="none"
  d="M 100,100 m -60,0 a 60,60 0 1,1 120,0 a 60,60 0 1,1 -120,0"
/>
<text className="circleText">
  <textPath href="#innerCirclePath">Hire Now •</textPath>
</text>
<text className="circleText">
  <textPath href="#innerCirclePath" startOffset="44%">
    Contact Me •
  </textPath>
</text>
</svg> */}
// <motion.div
// variants={awardVariants}
// initial="initial"
// animate="animate"
// className="awards"
// >
// <motion.h2 variants={awardVariants}>Top Rated Designer</motion.h2>
// <motion.p variants={awardVariants}>
//   Lorem ipsum dolor sit amet, consectetur adipisicing elit.
// </motion.p>
// <motion.div variants={awardVariants} className="awardList">
// <motion.img variants={awardVariants} src={award1} alt="Award 1" />
// <motion.img variants={awardVariants} src={award2} alt="Award 2" />
// <motion.img variants={awardVariants} src={award3} alt="Award 3" />
// </motion.div>
// </motion.div>
// {/* SCROLL SVG */}
// <motion.a
// animate={{ y: [0, 5], opacity: [0, 1, 0] }}
// transition={{
//   repeat: Infinity,
//   duration: 4,
//   ease: "easeInOut",
// }}
// href="#services"
// className="scroll"
// >
// <svg
//   width="50px"
//   height="50px"
//   viewBox="0 0 24 24"
//   fill="none"
//   xmlns="http://www.w3.org/2000/svg"
// >
//   <path
//     d="M5 9C5 5.13401 8.13401 2 12 2C15.866 2 19 5.13401 19 9V15C19 18.866 15.866 22 12 22C8.13401 22 5 18.866 5 15V9Z"
//     stroke="white"
//     strokeWidth="1"
//   />
//   <motion.path
//     animate={{ y: [0, 5] }}
//     transition={{
//       repeat: Infinity,
//       duration: 4,
//       ease: "easeInOut",
//     }}
//     d="M12 5V8"
//     stroke="white"
//     strokeWidth="1"
//     strokeLinecap="round"
//   />
// </svg>
// </motion.a>