import styles from './styles.module.scss';
import { useScroll, useTransform, motion } from 'framer-motion';
import { useRef } from 'react';
import Picture1 from "../../../assets/development2.png";
import Picture2 from "../../../assets/binary.png";
import Picture3 from "../../../assets/hacking2.png";
import Picture4 from "../../../assets/webdevelopment1.png";
import Picture5 from "../../../assets/webdevelopment4.png";

export default function Index() {
  const container = useRef(null);

  // Scroll progress setup
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start start', 'end end'],
  });

  // Define animations for each image
  const pictures = [
    {
      src: Picture3,
      scale: useTransform(scrollYProgress, [0, 1], [1, 4]),
      x: useTransform(scrollYProgress, [0, 0.33], [0, 0]),
      opacity: useTransform(scrollYProgress, [0, 0.33], [1, 1]),
    },
    {
      src: Picture2,
      scale: useTransform(scrollYProgress, [0, 1], [1, 5]),
      x: useTransform(scrollYProgress, [0.33, 0.66], [0, 200]),
      opacity: useTransform(scrollYProgress, [0.33, 0.66], [1, 0.5]),
    },
    {
      src: Picture1,
      scale: useTransform(scrollYProgress, [0, 1], [1, 6]),
      x: useTransform(scrollYProgress, [0.66, 1], [0, 0]),
      opacity: useTransform(scrollYProgress, [0.66, 1], [1, 0.5]),
    },
    {
      src: Picture4,
      scale: useTransform(scrollYProgress, [0, 1], [1, 8]),
      x: useTransform(scrollYProgress, [0.5, 1], [0, -300]),
      opacity: useTransform(scrollYProgress, [0.8, 1], [1, 0]),
    },
    {
      src: Picture5,
      scale: useTransform(scrollYProgress, [0, 1], [1, 9]),
      x: useTransform(scrollYProgress, [0.5, 1], [0, 300]),
      opacity: useTransform(scrollYProgress, [0.8, 1], [1, 0]),
    },
  ];

  return (
    <div ref={container} className={styles.container}>
      <div className={styles.sticky}>
        {pictures.map(({ src, scale, x, opacity }, index) => (
          <motion.div
            key={index}
            style={{ scale, x, opacity }}
            className={styles.el}
          >
            <div className={styles.imageContainer}>
              <img src={src} alt={`image-${index}`} className={styles.image} />
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}
